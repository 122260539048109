@import "./variables.scss";

body {
    margin: 0;
    font-family: $font-family-base;
}

.goHome {
    margin: auto;
    width: fit-content;
}

/*#region The switch - the box around the slider*/
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: #2196f3;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px #2196f3;
        }

        &:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }
    }
}
/*#endregion The switch - the box around the slider*/

// RESPONSIVE
// 0 - 480px
@media (max-width: $screen-xs-min) {
}

// 0 - 640px
@media (max-width: $screen-sm-min) {
}

// 0 - 1024px
@media (max-width: $screen-lg-min) {
}

// 0 - 1024px
@media (max-width: $screen-xl-min) {
}

// 480px - 640px
@media (min-width: $screen-xs) and (max-width: $screen-sm-min) {
}

// 640px - 1024px
@media (min-width: $screen-sm) and (max-width: $screen-lg-min) {
}

// 1024px - 1280px
@media (min-width: $screen-lg) and (max-width: $screen-xl-min) {
}
