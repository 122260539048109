@import "./variables.scss";

.teamDetailsPage {
    overflow: auto;
    width: 100%;
    display: flex;

    .teamDetailsPage--content {
        width: fit-content;
        margin: auto;

        .teamDetailsPage--content--shortcuts {
            display: flex;
            width: fit-content;
            margin: auto;

            > div {
                margin: 20px auto;

                @media (max-width: $screen-xs-min) {
                    margin: 30px 10px;
                    text-align: center;
                }
            }
        }

        .teamDetailsPage--content--data {
            width: 100%;
            margin: auto;

            .teamDetailsPage--content--data__match {
                margin: auto;
                width: fit-content;
            }
        }
    }
}
