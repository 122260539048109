@import "./variables.scss";

.refDetailsPage {
    display: inline-block;
    display: flex;

    .refDetailsPage--content {
        width: fit-content;
        margin: auto;

        .refDetailsPage--content__ddl {
            width: fit-content;
            margin: auto;
        }

        .refDetailsPage--content__refInfo {
            display: flex;
            width: fit-content;
            margin: auto;

            > * {
                @media (max-width: $screen-md-min) {
                    width: 50%;
                    padding: 10px;
                }
            }

            .refInfo--img {
            }

            .refInfo--extra {
                display: inline-block;
                text-align: left;
                margin: auto 0;

                .refInfo--extra__details {
                    @media (max-width: $screen-xs-min) {
                        font-size: $font-size-sm;
                    }

                    @media (min-width: $screen-xs) and (max-width: $screen-sm-min) {
                        font-size: $font-size-md;
                    }

                    @media (min-width: $screen-sm) and (max-width: $screen-md-min) {
                        font-size: $font-size-lg;
                    }
                }
                .refInfo--cards {
                    display: flex;
                    font-weight: bold;

                    @media (max-width: $screen-xs-min) {
                        font-size: $font-size-md;
                    }

                    @media (min-width: $screen-xs) and (max-width: $screen-sm-min) {
                        font-size: $font-size-lg;
                    }

                    @media (min-width: $screen-sm) and (max-width: $screen-md-min) {
                        font-size: $font-size-xl;
                    }

                    > * {
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        height: 30px;
                        width: 30px;
                        margin: auto;

                        span {
                            width: 30px;
                            display: block;
                            margin: 20px auto;
                            text-align: center;
                        }
                    }

                    .refInfo--cards__yellow {
                        background-image: url("./../img/yellowcard.svg");
                    }
                    .refInfo--cards__doubleyellow {
                        height: 30px;
                        width: 30px;
                        background-image: url("./../img/doubleyellow.png");
                    }
                    .refInfo--cards__red {
                        background-image: url("./../img/redcard.svg");
                    }
                }

                span {
                    display: block;
                    margin: 20px 0px;
                    background-color: white;
                }
            }
        }
        .refDetailsPage--content__matchDetails {
        }
    }
}
