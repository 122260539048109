@import "./variables.scss";

.matchDetailsPage {
    width: 100%;
    max-width: 1090px;
    margin: auto;
    padding: 5px;
    box-sizing: border-box;

    .matchDetailsPage__formations {
        display: block;
        width: 100%;
        margin: auto;

        // 0 - 480px
        @media (max-width: $screen-xs-min) {
            width: 100%;
        }

        .matchDetailsPage__formations--teams {
            display: flex;
            width: 100%;

            .teams__stats {
                width: 100%;
                > * {
                    > * {
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
            }
        }

        .matchDetailsPage__formations--stats {
            display: block;
            width: fit-content;
            margin: auto;

            > * {
                margin: auto;
            }
        }

        .matchDetailsPage__formations--players {
            display: flex;
            width: 100%;
            @media (max-width: $screen-lg-min) {
                width: 100%;
                display: block;
            }
            .players__component {
                width: 50%;
                display: flex;

                @media (max-width: $screen-lg-min) {
                    width: 100%;
                }

                &.home {
                    justify-content: right;
                }

                &.away {
                    justify-content: left;
                }
            }
        }

        .matchDetailsPage__formations--extra {
            display: flex;

            @media (max-width: $screen-md-min) {
                width: 100%;
                display: block;
            }

            .extra__mvp {
                width: 33%;
                margin: auto;

                @media (max-width: $screen-md-min) {
                    width: 100%;
                }
            }

            .extra__stats {
                width: 33%;
                margin: auto;
                @media (max-width: $screen-md-min) {
                    width: 100%;
                }
            }
            .extra__stadium {
                width: 33%;
                margin: auto;
                @media (max-width: $screen-md-min) {
                    width: 100%;
                }
            }

            .extra__mvpstadium {
                width: 100%;
                display: flex;

                > * {
                    > * {
                        border: 1px solid $main-color-green;
                    }
                }

                .extra__mvpstadium--mvp {
                    width: fit-content;
                    display: flex;
                    margin: auto;
                }
                .extra__mvpstadium--stadium {
                    margin: auto;
                }
            }
        }
    }
}
